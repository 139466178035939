import React, { createContext, useContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import USDCoinAddress from '../contractsData/USDCoin-address.json';
import USDCoinAbi from '../contractsData/USDCoin.json';
import AhoyTokenizedBoatsAddress from '../contractsData/AhoyTokenizedBoats-address.json';
import AhoyTokenizedBoatsAbi from '../contractsData/AhoyTokenizedBoats.json';
import AhoyMarketAddress from '../contractsData/AhoyMarket-address.json';
import AhoyMarketAbi from '../contractsData/AhoyMarket.json';
import RentalTermsManagerAddress from '../contractsData/RentalTermsManager-address.json';
import RentalTermsManagerAbi from '../contractsData/RentalTermsManager.json';
import AhoyRentalsAddress from '../contractsData/AhoyRentals-address.json';
import AhoyRentalsAbi from '../contractsData/AhoyRentals.json';
import PolygonAddresses from '../contractsData/137.json';
import _ from 'lodash';
import { removeURLfromIPFS, uploadImagetoIPFS, uploadJSONtoIPFS } from '../util/pinata';
const Web3Context = createContext();
import axios from 'axios';

export const Web3Provider = ({ children }) => {
  const [client, setClient] = useState(null);
  const [hasWeb3, setHasWeb3] = useState(false);
  const [usdcContract, setUsdcContract] = useState(null);
  const [boatsContract, setBoatsContract] = useState(null);
  const [marketContract, setMarketContract] = useState(null);
  const [rentalTermsContract, setRentalTermsContract] = useState(null);
  const [rentalContract, setRentalContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [creatingNFT, setCreatingNFT] = useState(false);
  const [nftBoatListing, setNftBoatListing] = useState(null);
  const [userRentalAgreement, setUserRentalAgreement] = useState(null);
  const [error, setError] = useState('');
  const backendURL = process.env.REACT_APP_BACKEND
  // const backendURL = `http://localhost:3001`

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        window.location.reload();
      });
      window.ethereum.on('accountsChanged', () => {
        window.location.reload();
      });
      setHasWeb3(true);
    }
  }, []);

  const web3Handler = async () => {
    if (!window.ethereum) return;
  
    try {
      setLoading(true);
  
      const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const chainId = await window.ethereum.request({ method: 'eth_chainId' });

      let appChainId, appChainParams, contractAddresses;
      // appChainId = '0x7A69'; 
      // appChainParams = {
      //   chainId: appChainId,
      //   chainName: 'Localhost', 
      //   nativeCurrency: {
      //     name: 'ETH', 
      //     symbol: 'ETH', 
      //     decimals: 18,
      //   },
      //   rpcUrls: ['http://127.0.0.1:8545'], 
      //   blockExplorerUrls: ['http://127.0.0.1:8545'], 
      // };
      // contractAddresses = {
      //   USDCoin: USDCoinAddress.address,
      //   AhoyTokenizedBoats: AhoyTokenizedBoatsAddress.address,
      //   AhoyMarket: AhoyMarketAddress.address,
      //   RentalTermsManager: RentalTermsManagerAddress.address,
      //   AhoyRentals: AhoyRentalsAddress.address,
      // };

      // for when we are using polygon
      appChainId = '0x89';
      appChainParams = {
        chainId: appChainId,
        chainName: 'Polygon Mainnet',
        nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
        rpcUrls: ['https://polygon-rpc.com/'],
        blockExplorerUrls: ['https://polygonscan.com/'],
      };
      contractAddresses = PolygonAddresses;
  
      // let appChainId, appChainParams, contractAddresses;
      // if (chainId === '0x7A69') { // Localhost
      //   appChainId = '0x7A69';
      //   appChainParams = {
      //     chainId: appChainId,
      //     chainName: 'Localhost',
      //     nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
      //     rpcUrls: ['http://127.0.0.1:8545'],
      //     blockExplorerUrls: ['http://127.0.0.1:8545'],
      //   };
      //   contractAddresses = {
      //     USDCoin: USDCoinAddress.address,
      //     AhoyTokenizedBoats: AhoyTokenizedBoatsAddress.address,
      //     AhoyMarket: AhoyMarketAddress.address,
      //     RentalTermsManager: RentalTermsManagerAddress.address,
      //     AhoyRentals: AhoyRentalsAddress.address,
      //   };
      // } else if (chainId === '0x89') { // Polygon Mainnet
      //   appChainId = '0x89';
      //   appChainParams = {
      //     chainId: appChainId,
      //     chainName: 'Polygon Mainnet',
      //     nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
      //     rpcUrls: ['https://polygon-rpc.com/'],
      //     blockExplorerUrls: ['https://polygonscan.com/'],
      //   };
      //   contractAddresses = PolygonAddresses;
      // } else {
      //   // If not on localhost or Polygon, switch to Polygon
      //   appChainId = '0x89';
      //   appChainParams = {
      //     chainId: appChainId,
      //     chainName: 'Polygon Mainnet',
      //     nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
      //     rpcUrls: ['https://polygon-rpc.com/'],
      //     blockExplorerUrls: ['https://polygonscan.com/'],
      //   };
  
      //   try {
      //     await window.ethereum.request({
      //       method: 'wallet_switchEthereumChain',
      //       params: [{ chainId: appChainId }],
      //     });
      //   } catch (switchError) {
      //     if (switchError.code === 4902) {
      //       try {
      //         await window.ethereum.request({
      //           method: 'wallet_addEthereumChain',
      //           params: [appChainParams],
      //         });
      //       } catch (addError) {
      //         console.error("Failed to add Polygon network:", addError);
      //       }
      //     } else {
      //       console.error("Failed to switch to Polygon network:", switchError);
      //     }
      //   }
      //   contractAddresses = PolygonAddresses;
      // }
      if (chainId !== appChainId) {
        try {
          // Try to switch to the app network
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: appChainId }],
          });
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask
          if (switchError.code === 4902) {
            try {
              // Try to add the app network
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [appChainParams],
              });
            } catch (addError) {
              console.error("Failed to add app network to MetaMask:", addError);
              setLoading(false);
              return;
            }
          } else {
            console.error("Failed to switch to app network:", switchError);
            setLoading(false);
            return;
          }
        }
        // contractAddresses = {
        //   USDCoin: USDCoinAddress.address,
        //   AhoyTokenizedBoats: AhoyTokenizedBoatsAddress.address,
        //   AhoyMarket: AhoyMarketAddress.address,
        //   RentalTermsManager: RentalTermsManagerAddress.address,
        //   AhoyRentals: AhoyRentalsAddress.address,
        // };
        //this for polygon
        contractAddresses = PolygonAddresses;
      }
  
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      // console.log('testing')
  
      loadContracts(signer, account, contractAddresses);
      setClient({
        account,
        signer,
        chainId: parseInt(appChainId, 16),
        provider,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error connecting to web3:", error);
      setLoading(false);
    }
  };
  
  const loadContracts = async (signer, account, addresses) => {
    const usdcContract = new ethers.Contract(addresses.USDCoin, USDCoinAbi.abi, signer);
    const boatsContract = new ethers.Contract(addresses.AhoyTokenizedBoats, AhoyTokenizedBoatsAbi.abi, signer);
    const marketContract = new ethers.Contract(addresses.AhoyMarket, AhoyMarketAbi.abi, signer);
    const rentalTermsContract = new ethers.Contract(addresses.RentalTermsManager, RentalTermsManagerAbi.abi, signer);
    const rentalContract = new ethers.Contract(addresses.AhoyRentals, AhoyRentalsAbi.abi, signer);

    const initialBalance = await usdcContract.balanceOf(account);
    // console.log({usdcContract})
    // console.log(`Initial USDC balance: ${formatUnits(initialBalance, 6)} USDC`);
  
    setUsdcContract(usdcContract);
    setBoatsContract(boatsContract);
    setMarketContract(marketContract);
    setRentalTermsContract(rentalTermsContract);
    setRentalContract(rentalContract);
  };
  


  const uploadImageToIpfs = async (image) => {
    try {
      const response = await uploadImagetoIPFS(image);
      if (response.success === true) {
        // console.log("Uploaded image to Pinata: ", response.pinataURL);
        return response.pinataURL;
      }
    } catch (e) {
      // console.log("Error during file upload", e);
      throw e;
    }
  };

  const uploadMetaDatatoIpfs = async (boatDetails) => {
    try {
      const response = await uploadJSONtoIPFS(boatDetails);
      if (response.success === true) {
        // console.log("Uploaded JSON to Pinata: ", response.pinataURL);
        return response.pinataURL;
      }
    } catch (e) {
      console.log("Error during metadata upload", e);
    }
  };

  const createNFTImage = async (uuid, file) => {
    const storageZoneName = 'ahoy-qr-code';
    const accessKey = '5d1b0c5d-fe35-41e6-8318d24247da-d5a9-40f3';
    const baseUrl = "storage.bunnycdn.com";
    const chosenImage = file || imageFile; 
    const fileName = uuid + '.' + chosenImage.type.split('/')[1];
    const url = `https://${baseUrl}/${storageZoneName}/${fileName}`;

    try {
      const uploadResponse = await axios.put(url, chosenImage, {
        headers: {
          "AccessKey": accessKey,
          "Content-Type": chosenImage.type
        },
      });
      // console.log(uploadResponse)
      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        const bunnyURL = `https://ahoy-qr-code.b-cdn.net/${fileName}`
        // console.log("Uploaded image to Bunny: ", bunnyURL);
        const requestBody = {
          imageUrl: bunnyURL
        };
        const midResponse = await axios.post(`${backendURL}/api/midjourney`, requestBody);
        if (midResponse.status === 200) {
          const midjourneyImageUrl = midResponse.data.midjourneyImageUrl;
          // console.log("Midjourney image URL: ", midjourneyImageUrl);

          const imageResponse = await axios.get(midjourneyImageUrl, {
            responseType: 'blob',
          });

          const file = new File([imageResponse.data], uuid + '.' + chosenImage.type.split('/')[1], { type: chosenImage.type });
          const uploadedImageUrl = await uploadImageToIpfs(file);
          return uploadedImageUrl
        } else {
          console.error("Failed to generate image with Midjourney");
        }
      } else {
        console.error("Failed to upload image to Bunny");
      }
    } catch (error) {
      console.error("Error during image upload or Midjourney interaction", error);
    }
  }


  // This is the first call made when someone is creating a listing, either for sale or to rent
  const createBoatNft = async ({ boatDetails, price, uuid }) => {
    if (!client) {
      // console.error("Client is not initialized.");
      return false;
    }
    
    const listingType = boatDetails.listingType;
    const refundabilityPeriod = parseInt(boatDetails.refundPeriod, 10) * 3600; // Convert hours to seconds
    const closedPeriod = parseInt(boatDetails.closedPeriod, 10) * 3600; // Convert hours to seconds
    const wholeDollarPrice = Math.floor(price / 100);
  
    let sellPrice = 0, dailyPrice = 0, hourlyPrice = 0, deposit = 0;
  
    if (listingType === "sale") {
      sellPrice = wholeDollarPrice;
    } else if (listingType === "hourly-rental") {
      hourlyPrice = wholeDollarPrice;
      deposit = Math.floor(boatDetails.deposit.amount / 100);
    } else if (listingType === "daily-rental") {
      dailyPrice = wholeDollarPrice;
      deposit = Math.floor(boatDetails.deposit.amount / 100);
    }

    const tokenId = await boatsContract.fromUuid(uuid);
    
    if (tokenId.isZero()) {
      try {
        if (imageFile) {
          const midjourneyImage = await createNFTImage(uuid);
          if (midjourneyImage) {
            boatDetails.nftImage = midjourneyImage;
            delete boatDetails.closedPeriod;
            delete boatDetails.refundPeriod;
            delete boatDetails.deposit;
            const metadataURL = await uploadMetaDatatoIpfs(boatDetails);
            if (metadataURL) {
              const requestBody = {
                chainId: client.chainId,
                account: client.account,
                metadataURL,
                uuid
              };
              const response = await axios.post(`${backendURL}/api/token`, requestBody);
              // console.log('Response from backend:', response.data);
    
              const tokenId = response.data.tokenId;
    
              try {
                if (listingType === 'hourly-rental' || listingType === 'daily-rental') {
                  let adjustedDailyPrice = 0, adjustedHourlyPrice = 0;
                  if (listingType === 'hourly-rental') {
                    adjustedHourlyPrice = hourlyPrice;
                    adjustedDailyPrice = hourlyPrice * 24;
                  } else {
                    adjustedDailyPrice = dailyPrice;
                    adjustedHourlyPrice = dailyPrice;
                  }
    
                  const securityDeposit = parseInt(deposit.toString());
                  setImageFile(null)
                  // setLoading(false);
                  await rentalTermsContract.setRentalTerms(
                    tokenId,
                    parseInt(adjustedHourlyPrice),
                    parseInt(adjustedDailyPrice),
                    closedPeriod,
                    refundabilityPeriod,
                    securityDeposit
                  );
                  return { success: true, message: "Created boat nft"};
                } else if (listingType === 'sale') {
                  setImageFile(null)
                  const gasLimit = 1000000; 
                  const sellPriceInUnits = ethers.utils.parseUnits(sellPrice.toString(), 6);
                  const approvedAddress = await boatsContract.getApproved(tokenId);
                  if (approvedAddress.toLowerCase() !== marketContract.address.toLowerCase()) {
                    const tx = await boatsContract.approve(marketContract.address, tokenId, { gasLimit });
                    await tx.wait();
                    // console.log("Approval successful");
                  }
                  await marketContract.createListedToken(tokenId, sellPriceInUnits);
                  return { success: true, message: "Created boat nft"};
                }
              } catch (contractError) {
                // console.error('Error with contract calls:', contractError);
                return { success: false, message: "Failed to create nft", contractError };
                // setLoading(false);
              }
            } else {
              // console.error('Metadata URL upload failed.');
              return { success: false, message: "Metadata URL upload failed."};
              // setLoading(false);
            }
          } else {
            // console.error('NFT Image creation failed.')
            return { success: false, message: "NFT Image creation failed."};
            // setLoading(false);
          }
        } else {
          // console.error('Image file is missing.');
          return { success: false, message: 'Image file is missing.'};
          // setLoading(false);
        }
      } catch (error) {
        // console.error('Error creating boat NFT:', error);
        return { success: false, message: 'Error creating boat NFT:'};
      }
    } else {
      try {
        if (listingType === 'hourly-rental' || listingType === 'daily-rental') {
          let adjustedDailyPrice = 0, adjustedHourlyPrice = 0;
          if (listingType === 'hourly-rental') {
            adjustedHourlyPrice = hourlyPrice;
            adjustedDailyPrice = hourlyPrice * 24;
          } else {
            adjustedDailyPrice = dailyPrice;
            adjustedHourlyPrice = dailyPrice;
          }

          const securityDeposit = parseInt(deposit.toString());
          setImageFile(null)
          await rentalTermsContract.setRentalTerms(
            tokenId,
            parseInt(adjustedHourlyPrice),
            parseInt(adjustedDailyPrice),
            closedPeriod,
            refundabilityPeriod,
            securityDeposit
          );
          return { success: true, message: "Created boat nft"};
        } else if (listingType === 'sale') {
          setImageFile(null)
          const sellPriceInUnits = ethers.utils.parseUnits(sellPrice.toString(), 6);

          const gasLimit = 1000000; 

          try {
            const approvedAddress = await boatsContract.getApproved(tokenId);
            if (approvedAddress.toLowerCase() !== marketContract.address.toLowerCase()) {
              const tx = await boatsContract.approve(marketContract.address, tokenId, { gasLimit });
              await tx.wait();
              // console.log("Approval successful");
            } 
            // else {
            //   console.log("Token already approved for market contract");
            // }
          } catch (error) {
            console.error("Error approving token:", error);
          }
          await marketContract.createListedToken(tokenId, sellPriceInUnits);
          return { success: true, message: "Created boat nft"};
        }
      } catch (contractError) {
        // console.error('Error with contract calls:', contractError);
        return { success: false, message: "Failed to create nft", contractError };
      }
    }
  
  };

  const updateNftImage = async ({ tokenId, uri, file, uuid }) => {
    if (!client) {
      console.error("Client is not initialized.");
      return { success: false, message: "Client not initialized" };
    }
  
    const shuffleString = (str) => {
      const arr = str.split('');
      for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
      }
      return arr.join('');
    };
  
    const newUuid = shuffleString(uuid);
    // console.log('New UUID:', newUuid);
  
    try {
      const midjourneyImage = await createNFTImage(newUuid, file);
      if (midjourneyImage) {
        const response = await fetch(uri);
        const oldData = await response.json();
  
        const oldImageUri = oldData.nftImage;  // Save the old image URI
  
        // Update the NFT image in the old data
        oldData.nftImage = midjourneyImage;
  
        const newTokenURI = await uploadMetaDatatoIpfs(oldData);
  
        // Remove old image from IPFS if new URI is created
        if (newTokenURI) {
          const removeOldImageUriResponse = await removeURLfromIPFS(oldImageUri);
          const removeOldDataUriResponse = await removeURLfromIPFS(uri);
          if (removeOldImageUriResponse.success && removeOldDataUriResponse.success) {
            const requestBody = {
              chainId: client.chainId,
              newTokenURI,
            };
  
            const updateResponse = await axios.put(`${backendURL}/api/token/${tokenId}`, requestBody);
            // console.log('Response from backend:', updateResponse.data);
  
            return { success: true, message: "NFT image updated successfully" };
          } else {
            return { success: false, message: "Failed to remove old image from IPFS" };
          }
        } else {
          return { success: false, message: "Failed to upload new metadata to IPFS" };
        }
      } else {
        return { success: false, message: "Failed to create NFT image" };
      }
    } catch (error) {
      console.error('Error updating NFT image:', error);
      return { success: false, message: "An error occurred while updating the NFT image", error };
    }
  };
  
  
  const updateBoat = async (uuid, boatDetails, tab, listingType) => {
    // console.log({tab})
    // console.log({boatDetails})
    if (!client) {
      console.error("Client is not initialized.");
      return { success: false, message: "Client not initialized" };
    }

    const tokenId = await boatsContract.fromUuid(uuid);
    
    if (tokenId.isZero()) {
        console.error("Invalid UUID. Token ID not found.");
        return { success: false, message: "Token ID not found" };
    }

    try {
        if (tab === 'details') {
            const updateResult = await updateBoatTokenURI(boatDetails, tokenId.toString());
            return updateResult;
        } 
        else if (tab === 'pricing') {
            const updateResult = await updateRentalPrices(boatDetails, tokenId.toString(), listingType);
            return updateResult;
        }
        else if (tab === 'pricing-and-stock') {
            const updateResult = await updateBoatSellPrice(boatDetails, tokenId.toString());
            return updateResult;
        }
        else if (tab === 'availability') {
            const updateResult = await updateRentalDepositRefund(boatDetails, tokenId.toString());
            return updateResult;
        }
        // Default response if the tab does not match any expected values
        else {
            return { success: false, message: "Invalid tab provided" };
        }
    } catch (error) {
        console.error('Error updating boat:', error);
        return { success: false, message: "Error updating boat" };
    }
  };

  const updateBoatSellPrice = async (boatDetails, tokenId) => {
    try {
      const newPrice = Math.floor(boatDetails.price.amount / 100);
      const parsedPrice = ethers.utils.parseUnits(newPrice.toString(), 'ether');
      
      // console.log('New price formatted:', ethers.utils.formatEther(parsedPrice));
  
      const askingPrice = await marketContract.getAskingPrice(tokenId);
      const readableOldPrice = ethers.utils.formatEther(askingPrice);
      
      // console.log('Old price formatted:', readableOldPrice);
      
      if (readableOldPrice == 0) {
        console.error("Asking price not found for the given Token ID.");
        return { success: false, message: "Asking price not found" };
      }
  
      if (readableOldPrice === ethers.utils.formatEther(parsedPrice)) {
        // console.log("New price is the same as the old price. No update required.");
        return { success: true, message: "New price is the same as the old price. No update required." };
      }
  
      const transaction = await marketContract.updateSellPrice(tokenId, parsedPrice);
      await transaction.wait();
      
      // console.log("Price updated successfully:", transaction);
      return { success: true, message: "Price updated successfully", transaction };
      
    } catch (error) {
      console.error("Error updating boat price:", error);
      if (error.data && error.data.message) {
        console.error("Detailed error message:", error.data.message);
      }
      return { success: false, message: "Failed to update price", error };
    }
  };
  

  const updateRentalDepositRefund = async (boatDetails, tokenId) => {
    try {
      const terms = await rentalTermsContract.terms(tokenId);
  
      const readableTerms = {
        hourlyPrice: terms[0],
        dailyPrice: terms[1],
        closedPeriod: Number(terms[2]),
        refundabilityPeriod: Number(terms[3]),
        securityDeposit: terms[4],
      };
  
      if (
        readableTerms.hourlyPrice === 0 ||
        readableTerms.dailyPrice === 0 ||
        readableTerms.closedPeriod === 0 ||
        readableTerms.refundabilityPeriod === 0 ||
        readableTerms.securityDeposit === 0
      ) {
        // console.log("Rental terms do not exist and can't be made for this boat");
        return { success: false, message: "Rental terms do not exist and can't be made for this boat" };
      }
  
      const newClosedPeriod = parseInt(boatDetails.publicData.closedPeriod, 10) * 3600;
      const newRefundPeriod = parseInt(boatDetails.publicData.refundPeriod, 10) * 3600;
  
      // console.log({newClosedPeriod})
      // console.log({newRefundPeriod})
      // console.log('old refund:', readableTerms.refundabilityPeriod)
      // console.log('old closed:', readableTerms.closedPeriod)
      if (
        newClosedPeriod === readableTerms.closedPeriod &&
        newRefundPeriod === readableTerms.refundabilityPeriod 
      ) {
        // console.log("No changes in rental terms");
        return { success: true, message: "No changes in rental terms" };
      }
  
      await rentalTermsContract.setRentalTerms(
        tokenId,
        readableTerms.hourlyPrice,
        readableTerms.dailyPrice,
        newClosedPeriod,
        newRefundPeriod,
        readableTerms.securityDeposit
      );
  
      // console.log('Rental terms updated');
      return { success: true, message: "Rental prices updated successfully", terms: readableTerms };
    } catch (error) {
      console.error('Error updating rental prices:', error);
      return { success: false, message: "Failed to update rental prices", error };
    }
  };
  

  const updateRentalPrices = async (boatDetails, tokenId, listingType) => {
    try {
      const terms = await rentalTermsContract.terms(tokenId);

      const readableTerms = {
          hourlyPrice: Number(terms[0].toString()),
          dailyPrice: Number(terms[1].toString()),
          closedPeriod: terms[2],
          refundabilityPeriod: terms[3],
          securityDeposit: Number(terms[4]),
      };
      // console.log({readableTerms})
      // Check if the rental terms are zero which means it doesnt exist
      if (
          readableTerms.hourlyPrice === 0 ||
          readableTerms.dailyPrice === 0 ||
          readableTerms.closedPeriod === 0 ||
          readableTerms.refundabilityPeriod === 0 ||
          readableTerms.securityDeposit === 0
      ) {
          // console.log("Rental terms do not exist and can't be made for this boat")
          return { success: false, message: "Rental terms do not exist and can't be made for this boat" };
      }

      const newDeposit = parseInt(Math.floor(boatDetails.publicData.deposit.amount / 100));
      const newRentalPrice = Math.floor(boatDetails.price.amount / 100);

      let newHourlyPrice, newDailyPrice;
      if (listingType === 'hourly-rental') {
          newHourlyPrice = parseInt(newRentalPrice);
          newDailyPrice = parseInt(newRentalPrice * 24);
      } else {
          newHourlyPrice = parseInt(newRentalPrice);
          newDailyPrice = parseInt(newRentalPrice);
      }

      // console.log({newHourlyPrice})
      // console.log({newDailyPrice})
      // console.log({newDeposit})

      if (
        newDeposit === readableTerms.securityDeposit &&
        newDailyPrice === readableTerms.dailyPrice && 
        newHourlyPrice === readableTerms.hourlyPrice  
      ) {
        // console.log("No changes in rental terms prices");
        return { success: true, message: "No changes in rental terms prices" };
      }


      await rentalTermsContract.setRentalTerms(
          tokenId,
          newHourlyPrice,
          newDailyPrice,
          readableTerms.closedPeriod,
          readableTerms.refundabilityPeriod,
          newDeposit
      );

      // console.log('rental terms updated');
      return { success: true, message: "Rental prices updated successfully", terms: readableTerms };
    } catch (error) {
        // console.error('Error updating rental prices:', error);
        return { success: false, message: "Failed to update rental prices", error };
    }
  };

  const updateBoatTokenURI = async (boatDetails, tokenId) => {

    const combinedBoatDetails = {
      ...boatDetails.publicData,
      description: boatDetails.description,
      title: boatDetails.title
    };
    // delete combinedBoatDetails.listing_type;
    delete combinedBoatDetails.transactionProcessAlias;
    delete combinedBoatDetails.unitType;

    try {
        const oldUri = await boatsContract.tokenURI(tokenId);
        const data = await fetch(oldUri);
        const oldData = await data.json();
        const { nftImage } = oldData;
        combinedBoatDetails.nftImage = nftImage;

        const response = await removeURLfromIPFS(oldUri);
        if (response.success) {
            const newTokenURI = await uploadMetaDatatoIpfs(combinedBoatDetails);
            if (newTokenURI) {
                const requestBody = {
                    chainId: client.chainId,
                    newTokenURI,
                };
                const response = await axios.put(`${backendURL}/api/token/${tokenId}`, requestBody);
                // console.log('Response from backend:', response.data);
                return { success: true, message: "Token URI updated successfully" };
            } else {
                // console.error("Failed to upload new metadata to IPFS");
                return { success: false, message: "Failed to upload new metadata to IPFS" };
            }
        } else {
            // console.error("Failed to remove old URI from IPFS");
            return { success: false, message: "Failed to remove old URI from IPFS" };
        }
    } catch (error) {
        // console.error('Error updating boat NFT URI:', error);
        return { success: false, message: "Error updating boat NFT URI" };
    }
};


  // Call this function to get boat for sale details
  const getBoatForSale = async ({ uuid }) => {
    if (!client) {
      // console.error("Client is not initialized.");
      return { success: false, message: "Client not initialized" };
    }
  
    try {
      // Get the tokenId from the UUID
      const tokenId = await boatsContract.fromUuid(uuid);
      
      // Check if the token ID is zero
      if (tokenId.isZero()) {
        // console.error("Invalid UUID. Token ID not found.");
        return { success: false, message: "Token ID not found" };
      }
  
      // Get the token URI
      const tokenURI = await boatsContract.tokenURI(tokenId);
      if (!tokenURI) {
        // console.error("Token URI not found for the given Token ID.");
        return { success: false, message: "Token URI not found" };
      }
  
      // Get the asking price from the market contract
      const askingPrice = await marketContract.getAskingPrice(tokenId);
      if (!askingPrice) {
        console.error("Asking price not found for the given Token ID.");
        return { success: false, message: "Asking price not found" };
      }

      const formattedPrice = Number(ethers.utils.formatUnits(askingPrice, 6)).toFixed(2);

      return {
        success: true,
        tokenId: tokenId.toString(),
        tokenURI,
        // askingPrice: askingPrice.toString(), this was working before, trying new code below
        askingPrice: formattedPrice
      };
    } catch (error) {
      console.error("Error getting boat for sale:", error);
      return { success: false, message: "Error getting boat for sale" };
    }
  };

  // Call this function to get boat for rent details
  const getBoatForRent = async ({ uuid }) => {
    if (!client) {
      console.error("Client is not initialized.");
      return { success: false, message: "Client not initialized" };
    }
  
    try {
      // Get the tokenId from the UUID
      const tokenId = await boatsContract.fromUuid(uuid);
      
      // Check if the token ID is zero
      if (tokenId.isZero()) {
        console.error("Invalid UUID. Token ID not found.");
        return { success: false, message: "Token ID not found" };
      }
  
      // Get the token URI
      const tokenURI = await boatsContract.tokenURI(tokenId);
      if (!tokenURI) {
        console.error("Token URI not found for the given Token ID.");
        return { success: false, message: "Token URI not found" };
      }
  
      // Get the rental terms from rentaltermsmanager contract
      const terms = await rentalTermsContract.terms(tokenId);
      // Convert the rental terms to a readable format
      const readableTerms = {
        hourlyPrice: Number(terms[0].toString()),
        dailyPrice: Number(terms[1].toString()),
        closedPeriod: (terms[2] / 3600).toString(),
        refundabilityPeriod: (terms[3] / 3600).toString(),
        securityDeposit: Number(terms[4].toString()),
      };
  
      // Return the data
      return {
        success: true,
        tokenId: tokenId.toString(),
        tokenURI,
        rentalTerms: readableTerms,
      };
    } catch (error) {
      console.error("Error getting boat for rent:", error);
      return { success: false, message: "Error getting boat for rent" };
    }
  };

  // This function is used to handle boat sale
  const handleBoatSale = async ({ tokenId, price }) => {
    if (!client) {
      console.error("Client is not initialized.");
      return;
    }
  
    try {
      const initialBalance = await usdcContract.balanceOf(client.account);
      // console.log(`Initial USDC balance: ${ethers.utils.formatUnits(initialBalance, 6)} USDC`);
  
      // Get the asking price in the smallest units (6 decimals for USDC)
      const askingPrice = await marketContract.getAskingPrice(tokenId);
      // console.log(`Asking Price (smallest units): ${askingPrice.toString()}`);
  
      // Format the asking price for display (human-readable)
      const formattedAskingPrice = ethers.utils.formatUnits(askingPrice, 6);
      // console.log(`Formatted Asking Price: ${formattedAskingPrice} USDC`);
  
      // For approval, use the askingPrice directly as it is already in the smallest units
      // console.log(`Parsed Asking Price for Approval: ${askingPrice.toString()}`);

      // const formattedPrice = Number(ethers.utils.formatUnits(askingPrice, 6)).toFixed(2);
  
      // Approve the market contract to spend USDC on behalf of the buyer
      // Use askingPrice directly for the approval
      const approvalTransaction = await usdcContract.approve(marketContract.address, askingPrice);
      await approvalTransaction.wait();
      // console.log("Approval transaction confirmed.");
  
      // Execute the sale
      const saleTransaction = await marketContract.executeSale(tokenId);
      await saleTransaction.wait();
      // console.log("Sale transaction confirmed.");
  
      // const finalBalance = await usdcContract.balanceOf(client.account);
      // console.log(`Final USDC balance: ${ethers.utils.formatUnits(finalBalance, 6)} USDC`);
  
      // console.log("Sale executed successfully:", saleTransaction);
      return { success: true, message: "Sale executed successfully" };
    } catch (error) {
      // console.error("Error executing boat sale:", error);
      // if (error.data && error.data.message) {
      //   console.error("Detailed error message:", error.data.message);
      // }
      return { success: false, message: "Error executing boat sale" };
    }
  };
  

  // This function we need when creating a rental
  const createRentalAgreement = async ({ listingType, price, tokenId, securityDeposit, bookingStart, bookingEnd, uuid }) => {
    try {
      setLoading(true)
      // console.log("Token ID:", tokenId);
      // console.log("Security Deposit:", securityDeposit);
      // console.log("Booking Start:", bookingStart);
      // console.log("Booking End:", bookingEnd);
      // console.log({ listingType });
      // console.log({ price });
      // console.log({ uuid });
  
      // Remove the dollar sign and commas from the price string, then convert it to a number
      const rentPrice = parseInt(price.replace(/[^0-9.-]+/g, ""));
    
      // Convert tokenId to an integer
      const parsedTokenId = parseInt(tokenId, 10);
    
      // Convert booking start and end dates to Unix timestamps
      const checkInDeadline = Math.floor(new Date(bookingStart).getTime() / 1000);
      const checkOutDeadline = Math.floor(new Date(bookingEnd).getTime() / 1000);
    
      // Calculate rental period in hours
      const rentalPeriodInHours = (checkOutDeadline - checkInDeadline) / 3600;
    
      // Log the transformed values
      // console.log("Check-in Deadline:", checkInDeadline);
      // console.log("Check-out Deadline:", checkOutDeadline);
      // console.log("Rental Period (hours):", rentalPeriodInHours);
      // console.log("Parsed Token ID:", parsedTokenId);
      // console.log("Parsed Rent Price:", rentPrice);
    
      // Convert total price
      const totalPrice = rentPrice + securityDeposit;
      const parsedTotal = ethers.utils.parseUnits(totalPrice.toString(), 6);
      // console.log("Total Deposit Price:", totalPrice);
  
      // Approve the rental contract to spend USDC on behalf of the user
      const approvalTransaction = await usdcContract.approve(rentalContract.address, parsedTotal);
      await approvalTransaction.wait();
  
      // Post the rental details to your backend
      const rentalDetails = {
        listingType,
        price,
        tokenId: parsedTokenId,
        securityDeposit: securityDeposit.toString(),
        bookingStart: checkInDeadline,
        bookingEnd: checkOutDeadline,
        uuid,
        clientAccount: client.account,
        totalPrice: totalPrice.toString(),
        chainId: client.chainId
      };
  
      const response = await axios.post(`${backendURL}/api/rental`, rentalDetails);
      // console.log('Response from backend:', response.data);
      setLoading(false)
      return { success: true, message: "Rental agreement executed successfully" };
    } catch (error) {
      // console.error("Error handling boat rental:", error);
      // if (error.data && error.data.message) {
      //   console.error("Detailed error message:", error.data.message);
      // }
      setLoading(false)
      return { success: false, message: "Error executing rental agreement" };
    }
  };

  const getBoatSellPrice = async (tokenId) => {
    try {
      const askingPrice = await marketContract.getAskingPrice(tokenId);
      const formattedPrice = ethers.utils.formatEther(askingPrice);
      return parseInt(Number(formattedPrice).toFixed(2), 10);
    } catch (error) {
      // console.error("Error getting boat sell price:", error);
      return null;
    }
  };

  const getBoatRentalTerms = async (tokenId) => {
    try {
      const terms = await rentalTermsContract.terms(tokenId);
      const readableTerms = {
        hourlyPrice: Number(terms[0].toString()),
        dailyPrice: Number(terms[1].toString()),
        closedPeriod: (terms[2] / 3600).toString(),
        refundabilityPeriod: (terms[3] / 3600).toString(),
        securityDeposit: Number(terms[4].toString()),
      };
      return readableTerms;
    } catch (error) {
      // console.error("Error getting boat rental terms:", error);
      return null;
    }
  };

  const getTokenId = async (uuid) =>{
    if (!client) {
      console.error("Client is not initialized.");
      return { success: false, message: "Client not initialized" };
    }
  
    try {
      const rentalId = await rentalContract.fromUuid(uuid);
  
      if (rentalId === 0) {
        // console.error("Invalid UUID. Rental ID not found.");
        return { success: false, message: "Rental ID not found" };
      }
      return rentalId;
    }
    catch(error) {
      // console.error('Error during getTokenId:', error);
      return null;
    }

  }

  const getRentalAgreement = async (uuid) => {
    if (!client) {
      // console.error("Client is not initialized.");
      return { success: false, message: "Client not initialized" };
    }
  
    try {
      const rentalId = await rentalContract.fromUuid(uuid);
  
      if (rentalId === 0) {
        // console.error("Invalid UUID. Rental ID not found.");
        return { success: false, message: "Rental ID not found" };
      }
  
      // Get the rental agreement details
      const agreement = await rentalContract.rentalAgreements(rentalId);
  
      if (agreement.boatId.toString() == 0) {
        // console.error("Rental agreement not found for the given Rental ID.");
        return { success: false, message: "Rental agreement not found" };
      }
  
      const boatOwner = await boatsContract.ownerOf(agreement.boatId);
      const isBoatOwner = client.account.toLowerCase() === boatOwner.toLowerCase();
      const isRenter = client.account.toLowerCase() === agreement.renter.toLowerCase();
  
      const checkInTime = await rentalContract.checkInTime(rentalId);
      const checkOutTime = await rentalContract.checkOutTime(rentalId);
      const inspectionStatus = await rentalContract.inspectionPassed(rentalId);
      const rentalCompleted = await rentalContract.rentalCompleted(rentalId);
  
      // Convert the rental agreement to a readable format
      const readableAgreement = {
        boatId: agreement.boatId.toString(),
        renter: agreement.renter,
        totalPaid: parseFloat(ethers.utils.formatUnits(agreement.deposit, 18)).toFixed(2),
        rentalFee: parseFloat(
          ethers.utils.formatUnits(agreement.deposit, 18) - ethers.utils.formatUnits(agreement.securityDeposit, 18)
        ).toFixed(2),
        securityDeposit: parseFloat(ethers.utils.formatUnits(agreement.securityDeposit, 18)).toFixed(2),
        checkInDeadline: new Date(agreement.checkInDeadline.toNumber() * 1000).toLocaleString(),
        checkOutDeadline: new Date(agreement.checkOutDeadline.toNumber() * 1000).toLocaleString(),
        rentalId: rentalId.toString(),
        uuid,
        boatOwnerAddress: boatOwner,
        isBoatOwner,
        isRenter,
        checkInTime: checkInTime.toNumber() === 0 ? null : new Date(checkInTime.toNumber() * 1000).toLocaleString(),
        checkOutTime: checkOutTime.toNumber() === 0 ? null : new Date(checkOutTime.toNumber() * 1000).toLocaleString(),
        inspectionPassed: inspectionStatus,
        rentalCompleted: rentalCompleted,
      };
  
      return { success: true, agreement: readableAgreement };
    } catch (error) {
      // console.error("Error getting rental agreement:", error);
      return { success: false, message: "Error getting rental agreement", error };
    }
  };
  

  const handleCheckIn = async (tokenId) => {
    try {
      const transaction = await rentalContract.checkIn(tokenId);
      await transaction.wait();
      // console.log('Check-in successful:', transaction);
      return { success: true, message: 'Check-in successful' };
    } catch (error) {
      // console.error('Error during check-in:', error);
      return { success: false, message: 'Error during check-in', error: error.message };
    }
  };

  const handleCheckOut = async (tokenId) => {
    try {
      const transaction = await rentalContract.checkOut(tokenId);
      await transaction.wait();
      // console.log('Check-out successful:', transaction);
      return { success: true, message: 'Check-out successful' };
    } catch (error) {
      // console.error('Error during check-out:', error);
      return { success: false, message: 'Error during check-out', error: error.message };
    }
  };

  const handleInspection = async (tokenId) => {
    try {
      const transaction = await rentalContract.setInspectionPassed(tokenId, true);
      await transaction.wait();
      // console.log('Inspection successful:', transaction);
      return { success: true, message: 'Inspection successful' };
    } catch (error) {
      // console.error('Error during inspection', error);
      return { success: false, message: 'Error during inspection', error: error.message };
    }
  };

  const handleCompletion = async (tokenId) => {
    try {

      // const initialBalance = await usdcContract.balanceOf(client.account);
      // console.log(`Initial USDC balance: ${formatUnits(initialBalance, 18)} USDC`);

      const transaction = await rentalContract.completeRental(tokenId);
      await transaction.wait();
      // console.log('Rental completion successful:', transaction);

      // const finalBalance = await usdcContract.balanceOf(client.account);
      // console.log(`Final USDC balance: ${formatUnits(finalBalance, 18)} USDC`);
      return { success: true, message: 'Rental completion successful' };
    } catch (error) {
      // console.error('Error during rental completion', error);
      return { success: false, message: 'Error during rental completion', error: error.message };
    }
  };

  return (
    <Web3Context.Provider value={{ client, hasWeb3, web3Handler, boatsContract, rentalContract, marketContract, rentalTermsContract, loading, setLoading, setImageFile, createBoatNft, updateBoatTokenURI, getBoatForSale, updateBoatSellPrice, handleBoatSale, getBoatForRent, createRentalAgreement, creatingNFT, setCreatingNFT, updateBoat, updateNftImage, getBoatSellPrice, nftBoatListing, setNftBoatListing, getBoatRentalTerms, getRentalAgreement, setUserRentalAgreement, userRentalAgreement, handleCheckIn, handleCheckOut, handleInspection, handleCompletion, getTokenId, setError, error}}>
      {children}
    </Web3Context.Provider>
  );
};

export const useWeb3 = () => {
  return useContext(Web3Context);
};
